/******************************************************************************/
/***** armor *****/
/******************************************************************************/
.armor-wrapper {
  position: relative;
  z-index: 1;
  float: left;
  width: 70%;
  margin-bottom: 2%;
  margin-left: 15%;
  clear: both;
}

.armor {
  position: relative;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
}


.armor-title {
  font-style: italic;
  position: absolute;
  top: -20px;
  font-size: 20px;
  background-color: var(--titleColor);
  color: var(--titleTextColor);
  margin: 0 auto;
}

.armor-row-1 {
  display: block;

}

.armor-field {
  float: left;
  width: 25%;  
  margin-bottom: 1%;
}

.armor-title {
  text-align: center;
  position: relative;
  top: -20px;
  color: var(--titleTextColor);
  background-color: var(--titleColor);
  clear: both;
  width: 40%;

}

.armor-row {
  overflow: auto;
  margin: 0px auto;
  position: relative;
}

.armor .field-label {
  text-align: center;
}

.armor-wrapper input {
  width: 90%;
}
