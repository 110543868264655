/******************************************************************************/
/***** attack *****/
/******************************************************************************/
.attack-wrapper {
  position: relative;
  z-index: 1;
  float: left;
  width: 48%;
  text-align: center;
  margin-bottom: 2%;
}

.attack {

}

.attack-title {
  position: relative;
  width: 40%;
  margin: 0 auto;
  top: -10px;
  font-size: 20px;
  font-style: italic;
  text-align: center;
  color: var(--titleTextColor);
  background-color: var(--titleColor);
  clear: both;
}

.attack-row-1 {
  display: block;
}

.attack-field {
  float: left;
  width: 17%;
  margin-left: 2%;
  margin-bottom: 1%;
}



.attack-row {
  overflow: auto;
  margin: 0px auto;
  position: relative;
}

.attack .field-label {
  text-align: center;
}

.attack .field-label,
.health .field-label {
  background-color: transparent;
}
