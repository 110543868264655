/******************************************************************************/
/***** Essence *****/
/******************************************************************************/
.essence-wrapper-main {
  width: 100%;
  clear: both;
}
.essence-wrapper {
  position: relative;
  float: left;
  width: 25%;
  z-index: 1;
}

.essence {
  position: relative;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
}


.essence-title {
  font-style: italic;
  width: 80%;
  position: relative;
  top: -20px;
  font-size: 20px;
  background-color: var(--titleColor);
  color: var(--titleTextColor);
  margin: 0 auto;
}

.essence-title input,
.defense input {
  position: relative;
  top: -1px;
  width: 20px;
}

.essence-val-wrapper {
  display: inline-block;
}


/***** Defense *****/
.defense {
  margin: 0 auto;
}

.defense-val-wrapper{
  display: inline-block;
}

.defense-calc-wrapper div {
  float: left;
  margin: 4px;
  margin-bottom: 15px;
  margin-top: 10px;
}
.defense-base,
.defense-plus {
  padding-top: 3px;
}

.defense-calc-wrapper {
  white-space: nowrap;
  display: flex;
  justify-content: center;
}
.defense-calc-wrapper input {
  width: 20px;

  //border: none;
}
/***** Skill *****/
.skill-wrapper {
  position: relative;
  padding: none;
  clear: left;
}

.skill {
    display: flow-root;
    font-style: italic;
    clear: left;
    text-align: left;

    margin-bottom: 5px;
    padding-bottom: 10px;
    z-index: 1;
    top: -2px;
}

.specialization-main {
  margin-top: 2px;
}

.specialization-radio-wrapper {
  z-index: 2;
}


.skill-title {
  padding: 5px 5px 5px 5px;
  padding-left: 3%;

}


.label-specialization {
  writing-mode: tb-rl;
  transform: rotateZ(-180deg);
  -webkit-transform: rotateZ(-180deg);
  float: left;
  font-size: 8px;
  padding: 2px;
}

.essence-wrapper input {
  width: 100%;
  text-align: center;
}

.skill-wrapper input {
  width: 90%;
}


.skill-title {
  float: left;
}

.skill-dice {
  display: flex;
  position: relative;
  float: right;
  font-size: 10px;
  margin-top: 5px;
  margin-right: 5px;
}

.skill-dice div {
}

.skill-dice .field-label {

  width: 100%;
  background-color: transparent;
}

.specializations {
  clear: both;
}

.specializations .field-radio-wrapper {
  position: absolute;
}

.label-specialization {
  .margin-right: 5px;
}
