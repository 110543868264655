.sheet-wrapper {
  align: center;
  text-align: center;
  margin: 0 auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.sheet {
  position: absolute;
  width: 90%;
  height: 225%;
  max-width: 1200px;
  justify-content:center;
  z-index: -3;

}

.sheet-background {
  position: absolute;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  margin-left: 2.5%;
  background-color: var(--borderColor);
  top: -.25%;
}

.sheet-background:after {
  background-color: var(--sheetColor);
}

.sheet-background,
.sheet-background:before,
.sheet-background:after {
  clip-path: polygon(
      0% var(--notchSize20),                 /* top left */
      var(--notchSize20) 0%,                 /* top left */
      calc(100% - var(--notchSize20)) 0%,                   /* top right */
      100% var(--notchSize20 ),                              /* top right */
      100% calc(100% - var(--notchSize20)),  /* bottom right */
      calc(100% - var(--notchSize20)) 100%,   /* bottom right */
      var(--notchSize20) 100%,                               /* bottom left */
      0% calc(100% - var(--notchSize20))                      /* bottom left */
    );
}

.sheet-background:after {
  background-color: var(--sheetColor);
}
