.main-header-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
  top: -95%;
  justify-content: center;
}

.main-header {
  display: block;
  position: absolute;
  width: 35%;
}


.main-header-highlight:before,
.main-header:before {
  content: ' ';
  position: absolute;
  width: calc( 100% - 4px );
  height: calc( 100% - 4px );
  top: 2px;
  left: 2px;
  z-index: -1;
  background-color: var(--backgroundColor);
}

.main-header-highlight:after,
.main-header:after {
  content: ' ';
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--borderColor);
  z-index: -2;
  height: calc(100%);
  width: calc(100%);
}


.main-header-left,
.main-header-right {
  position: absolute;
  width: 20%;
  height: 100%;
  content: ' ';
  background-color: transparent;
  top: 0px;
}

.main-header-right {
  right: 0px;
}



.main-header-transformers {
  position: relative;
  width: 50%;
  font-size: 35px;
  margin: 0 auto;


}

.main-header-mtmte {
  position: relative;
  width: 50%;
  font-size: 15px;
  color: white;
  background-color: black;
  margin: 0 auto;
  margin-bottom: 5px;
}

.main-header-rpg {
  position: relative;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 5px;
  background-color: black;
  font-size: 20px;
  color: white;
}

.main-header-rpg {
  clip-path: polygon(
      0% 100%,                 /* top left */
      0px 0%,                 /* top left */
      calc(100% - 0px) 0%,                   /* top right */
      100% 0px,                              /* top right */
      100% calc(100% - var(--notchSize8) ),  /* bottom right */
      calc(100% - var(--notchSize8)) 100%,   /* bottom right */
      var(--notchSize8) 100%,                /* bottom left */
      0% calc(100% - var(--notchSize8))      /* bottom left */
    );
}

.main-header-highlight,
.main-header-highlight:before,
.main-header-highlight:after {
  clip-path: polygon(
      0% var(--notchSize20),                 /* top left */
      var(--notchSize20) 0%,                 /* top left */
      calc(100% - 0px) 0%,                   /* top right */
      100% 0px,                              /* top right */
      100% calc(100% - var(--notchSize20) ),  /* bottom right */
      calc(100% - var(--notchSize20)) 100%,   /* bottom right */
      0px 100%,                               /* bottom left */
      0 calc(100% - 0px)                      /* bottom left */
    );
}



.main-header-highlight {
  display: flex;
  position: relative;
  width: 80%;
  height: 20%;
  content: ' ';
}

.main-header-highlight {
  margin-left: 3%;
  margin-top: 10%;

}
