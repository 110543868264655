.perks-gear-bonds-wrapper .field-label {
  width: 85%;
  text-align: center
}

.perks-gear-bonds-wrapper {
  position: relative;
  float: left;
  z-index: 1;
  width: 90%;
  margin: 0 auto;
  margin-left: 4%;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 10px;
  text-align: center;
}

.perks-wrapper,
.gear-wrapper,
.bonds-wrapper {
  position: relative;
  float: left;
  width: 31.5%;
}

.perks-gear-bonds-wrapper textarea {
  width: 90%;
}



.perks-wrapper,
.perks-wrapper:after,
.perks-wrapper textarea {
  clip-path: polygon(
      0% var(--notchSize20),                 /* top left */
      var(--notchSize20) 0%,                 /* top left */
      calc(100% - 0px) 0%,                   /* top right */
      100% 0px,                              /* top right */
      100% 0%,  /* bottom right */
      100% 100%,   /* bottom right */
      0px 100%,                               /* bottom left */
      0 calc(100% - 0px)                      /* bottom left */
    );
}


.bonds-wrapper,
.bonds-wrapper:after,
.bonds-wrapper textarea {
  clip-path: polygon(
      0% 100%,                 /* top left */
      00% 0%,                 /* top left */
      calc(100% - 0px) 0%,                   /* top right */
      100% 0px,                              /* top right */
      100% calc(100% - var(--notchSize20)),  /* bottom right */
      calc(100% - var(--notchSize20)) 100%,   /* bottom right */
      0px 100%,                               /* bottom left */
      0 calc(100% - 0px)                      /* bottom left */
    );
}

.perks-wrapper:after,
.gear-wrapper:after,
.bonds-wrapper:after {
  background-color: var(--backgroundColor);;
  content: ' ';
  width: calc( 100% - 2px );
  height: calc( 100% - 2px );
  position: absolute;
  z-index: -2;
  top:  1px;
  left: 1px;
}

.perks-wrapper textarea,
.gear-wrapper textarea,
.bonds-wrapper textarea {
  width: 95%;
  border: none;
  position: relative;
  left: 0px;
}

.perks-wrapper,
.gear-wrapper,
.bonds-wrapper{
  position: abosolute;
  left: 0px;
  background-color: var(--borderColor);
  z-index: -1;
  margin-left: .5%;
  padding-bottom: 1%;
}


.perks-gear-bonds .field-label {
  position: relative;
  top: 1px;
}
