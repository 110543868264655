.control-panel-wrapper {
  display: block;
  position: absolute;
  height: 100%;
  width: 15%;
  right: 0px;
  top: 12.5%;
  background-color: black;
}

.control-panel-wrapper:after {
  content: ' ';
  position: absolute;
  width: calc( 100% - 4px );
  height: calc( 100% - 4px );
  top: 2px;
  left: 2px;
  z-index: -1;
  background-color: var(--sheetColor);
}

.control-panel-wrapper,
.control-panel-wrapper:before,
.control-panel-wrapper:after {
  clip-path: polygon(
      0% var(--notchSize20),                 /* top left */
      var(--notchSize20) 0%,                 /* top left */
      calc(100% - var(--notchSize20)) 0%,                   /* top right */
      100% var(--notchSize20 ),                              /* top right */
      100% calc(100% - var(--notchSize20)),  /* bottom right */
      calc(100% - var(--notchSize20)) 100%,   /* bottom right */
      var(--notchSize20) 100%,                               /* bottom left */
      0% calc(100% - var(--notchSize20))                      /* bottom left */
    );
}
