.health-wrapper {
  position: relative;
  float: left;
  width: 48%;
  z-index: 1;
  margin-top: 1%;
  margin-bottom: 2%;
  margin-right: 4%;
}

.health {

}

.health-column-1 {
  float: left;
  width: 48%;
  margin-right: 2%;
}

.health-column-2 {
  float: left;
  width: 40%;
  margin-top: 2%;
  margin-left: 2%;
}

.influences-wrapper,
.hangups-wrapper {
  margin-top: 2%;
  margin-left: 2%;
}

.health-wrapper textarea {
  position: relative;
  width: 95%;
  border: none;
}

.hangups-wrapper {
  margin-bottom: 2%;
}

.influences-wrapper textarea {
  text-indent: 10%;
}

.energon-wrapper,
.movement-wrapper {
  float: left;
  width: 48%;
  margin-right: 2%;
}

.energon-wrapper input,
.movement-wrapper input,
.hlth-wrapper input {
  width: 95%;
}

.hlth-wrapper {
  width: 100%;
  margin-top: 20%;
  position: relative;
}

.influences-wrapper,
.influences-wrapper:after,
.influences-wrapper textarea {
  clip-path: polygon(
      0% var(--notchSize20),                 /* top left */
      var(--notchSize20) 0%,                 /* top left */
      calc(100% - 0px) 0%,                   /* top right */
      100% 0px,                              /* top right */
      100% 0%,  /* bottom right */
      100% 100%,   /* bottom right */
      0px 100%,                               /* bottom left */
      0 calc(100% - 0px)                      /* bottom left */
    );
}


.hangups-wrapper,
.hangups-wrapper:after,
.hangups-wrapper textarea {
  clip-path: polygon(
      0% 100%,                 /* top left */
      00% 0%,                 /* top left */
      calc(100% - 0px) 0%,                   /* top right */
      100% 0px,                              /* top right */
      100% calc(100% - var(--notchSize20)),  /* bottom right */
      calc(100% - var(--notchSize20)) 100%,   /* bottom right */
      0px 100%,                               /* bottom left */
      0 calc(100% - 0px)                      /* bottom left */
    );
}


.separator-wrapper-main {}
.separator-wrapper {
  position:absolute;
  height: 100%;
  width: 100%;
  padding-left: 5%;
  z-index: -1;
}

.separator {
  content: ' ';
  position: relative;
  z-index: -1;
  float: left;
  width: 12.5%;
  height: 15%;
  top: -20%;

}

.influences-wrapper,
.hangups-wrapper {
  position: relative;
  width: calc( 100% );
  height: calc( 100% );
  left: 0px;
  padding-top: 1%;
  padding-left: 1%;
  background-color: var(--borderColor);
}

.influences-wrapper:after,
.hangups-wrapper:after {
  width: calc( 100% - 2px );
  height: calc( 100% - 2px );
  top:  1px;
  left: 1px;
}

.damage-wrapper {
  position: relative;
  float: left;
}

.damage-wrapper .field-wrapper {
  position: relative;
  float: left;
  z-index: 2;
}
