/************************************************************
FONTS
************************************************************/
@font-face {
    font-family: 'sf_transroboticsbold_italic';
    src: url('./fonts/sf_transrobotics_bold_italic-webfont.woff2') format('woff2'),
         url('./fonts/sf_transrobotics_bold_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sf_transroboticsbold';
    src: url('./fonts/sf_transrobotics_bold-webfont.woff2') format('woff2'),
         url('./fonts/sf_transrobotics_bold-webfont.woff') format('woff');
    font-weight: no7rmal;
    font-style: normal;
}
@font-face {
    font-family: 'sf_transroboticsitalic';
    src: url('./fonts/sf_transrobotics_italic-webfont.woff2') format('woff2'),
         url('./fonts/sf_transrobotics_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'sf_transroboticsregular';
    src: url('../fonts/sf_transrobotics-webfont.woff2') format('woff2'),
         url('../fonts/sf_transrobotics-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


:root {
  --notchSize8: 8px;
  --notchSize20: 30px;
  --notchSize50: 80px;

  --backgroundColor: white;
  --borderColor: black;
  --titleColor: black;
  --titleTextColor: white;
  --fontColor: black;
  --inputColor: blue;
  --inputBackgroundColor: #cbcbcb;
  --sheetColor: gray;
}

body {
  font-size: 14px;
  font-family: sf_transroboticsregular;
  color: var(--fontColor);
}

input,
textarea {
  font-size: 16px;
  font-family: 'Cybertron Metals';
  color: var(--inputColor);
  background-color: var(--inputBackgroundColor);
  text-align: center;
  padding-top: 3px;
  border: none;
}

textarea {
  font-size: 16px;
}

input:focus,
textarea:focus {
  outline: none;

}

input::-moz-selection,
textarea:-moz-selection {
  outline: none;
}

.field-label {
  position: relative;
  margin: 0 auto;
  text-align: center;
  background-color: var(--backgroundColor);
}


/************************************************************
NOTCHES
************************************************************/
input[type="text"],
input[type="password"],
.notched2,
.skill:before,
.skill:after,
.essence-title,
.attack-title,
.alt-mode-title,
.armor-title,
.alt-mode-attacks-title,
.hardpoints-title,
.notes-title {
  clip-path: polygon(
      0% var(--notchSize8),                 /* top left */
      var(--notchSize8) 0%,                 /* top left */
      calc(100% - 0px) 0%,                   /* top right */
      100% 0px,                              /* top right */
      100% calc(100% - var(--notchSize8) ),  /* bottom right */
      calc(100% - var(--notchSize8)) 100%,   /* bottom right */
      0px 100%,                               /* bottom left */
      0 calc(100% - 0px)                      /* bottom left */
    );
}

.main-header:before,
.main-header:after,
.separator,
.separator:before,
.separator:after,
.info:before,
.info:after,
.health:before,
.health:after,
.attack:before,
.attack:after,
.hardpoints:before,
.hardpoints:after,
.armor:before,
.armor:after,
.alt-mode:before,
.alt-mode:after,
.alt-mode-attacks:before,
.alt-mode-attacks:after,
.perks-gear-bonds:before,
.perks-gear-bonds:after,
.notes:before,
.notes:after,
.perks-gear-bonds-wrapper:before,
.perks-gear-bonds-wrapper:after {
  clip-path: polygon(
      0% var(--notchSize20),                 /* top left */
      var(--notchSize20) 0%,                 /* top left */
      calc(100% - 0px) 0%,                   /* top right */
      100% 0px,                              /* top right */
      100% calc(100% - var(--notchSize20) ),  /* bottom right */
      calc(100% - var(--notchSize20)) 100%,   /* bottom right */
      0px 100%,                               /* bottom left */
      0 calc(100% - 0px)                      /* bottom left */
    );
}

.notched4,
.essence:before,
.essence:after {
  clip-path: polygon(
      0% var(--notchSize8),                 /* top left */
      var(--notchSize8) 0%,                 /* top left */
      calc(100% - var(--notchSize8)) 0%,                   /* top right */
      100% var(--notchSize8 ),                              /* top right */
      100% calc(100% - var(--notchSize8)),  /* bottom right */
      calc(100% - var(--notchSize8)) 100%,   /* bottom right */
      var(--notchSize8) 100%,                               /* bottom left */
      0% calc(100% - var(--notchSize8))                      /* bottom left */
    );
}


.notched2-bg,
.sheet-background:after,
.info:before,
.health:before,
.attack:before,
.essence:before,
.skill:before,
.perks-gear-bonds:before,
.hardpoints:before,
.armor:before,
.alt-mode:before,
.alt-mode-attacks:before,
.notes:before,
.separator:before,
.influences-wrapper:after,
.hangups-wrapper:after,
.skill:after {
  content: ' ';
  position: absolute;
  width: calc( 100% - 4px );
  height: calc( 100% - 4px );
  top: 2px;
  left: 2px;
  z-index: -1;
  background-color: var(--backgroundColor);
}

.separator:after,
.info:after,
.health:after,
.attack:after,
.essence:after,
.skill:after,
.perks-gear-bonds:after,
.hardpoints:after,
.armor:after,
.alt-mode:after,
.alt-mode-attacks:after,
.notes:after {
  content: ' ';
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--borderColor);
  z-index: -2;
  height: calc(100%);
  width: calc(100%);
}
