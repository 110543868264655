.notes-wrapper {

}

.notes {
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  padding: 10px;
}


.notes textarea {

}



.notes-title {
  font-style: italic;
  position: relative;
  top: -20px;
  font-size: 20px;
  background-color: var(--titleColor);
  color: var(--titleTextColor);
  margin: 0 auto;
  width: 20%;
}

.notes .field-label {
  background-color: transparent;
}
