.character-list-wrapper {
  /*position: absolute;*/
  display: flow-root;
  right: 0px;
  width: 100%;
}

.character-list-title {
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
  background-color: black;
  color: white;
}

.character-list-item {
  float: left;
  height: 100px;
  width: 33.3%;
  font-size: 16px;
  text-align: center;
}

.character-list-item-pic img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
