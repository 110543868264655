/******************************************************************************/
/***** hardpoints *****/
/******************************************************************************/
.hardpoints-wrapper {
  width: 85%;
  position: relative;
  float: left;
  margin-bottom: 2%;
  margin-left: 7%;
  align: center;
}


.hardpoints {
  padding: 10px;
  text-align: center;
  position: relative;
  margin: 0 auto;
  z-index: 1;
}


.hardpoints-title {
  font-style: italic;
  position: absolute;
  top: -20px;
  font-size: 20px;
  background-color: var(-titleColor);
  color: var(--titleTextColor);
  margin: 0 auto;
}

.hardpoints .field-label {
  width: 100%;
}


.hardpoints-row-1 {
  display: block;

}

.hardpoints-field-wrapper {
  float: left;
  width: 12%;
  margin-left: 2%;
  margin-bottom: 1%;
}

.hardpoints-title {
  text-align: center;
  position: relative;
  top: -20px;
  color: var(--titleTextColor);
  background-color: var(--titleColor);
  clear: both;
  width: 40%;

}

.hardpoints-row {
  overflow: auto;
  margin: 0px auto;
  position: relative;
}

.hardpoints .field-label {
  text-align: center;
}

.hardpoints-wrapper input {
  width: 100%;
}

.hardpoints-name-1-wrapper,
.hardpoints-range-1-wrapper,
.hardpoints-hardpoints-1-wrapper,
.hardpoints-effects-1-wrapper,
.hardpoints-notes-1-wrapper {
  clear: right;
}
