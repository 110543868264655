/******************************************************************************/
/***** Info *****/
/******************************************************************************/
.info-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 1%;
  margin-bottom: 2%;
  padding-bottom: .5%;
  z-index: 1;
}

.info {
  width: 100%;
}


.info .field-wrapper
   {
  position: relative;
  float: left;
  padding-top: 2%;
}

.field-wrapper input,
.field-wrapper textarea {
  width: 100%;
}

.info-column-1,
.info-column-2,
.info-column-3 {
  overflow: auto;
  float: left;
}

.info-column-1 {
  width:50%;
  overflow: auto;
}

.info-column-2,
.info-column-3 {
  width:25%;
  overflow: auto;
}

.name-wrapper,
.origin-wrapper {
  clear: left;
  margin-left: 10%;
}

.name-wrapper {
  width: 64%;
  margin-right: 2%;
}

.pronouns-wrapper {
  width: 21%;
  margin-right: 2%;
}

.description-wrapper,
.langauges-wrapper {
  width: 95%;
  margin-right: 2%;
}

.origin-wrapper {
  width: 38%;
  margin-right: 2%;
}

.role-wrapper {
  width: 28%;
  margin-right: 2%;
}

.level-wrapper {
  width: 17%;
  margin-right: 2%;
}

.info .info-column-3 .field-wrapper {
  width: 85%;
}

.faction {
  position: relative;
}

.faction-Autobot-wrapper ,
.faction-Decepticon-wrapper {
  position: relative;
  float: left;
  margin: 0 auto;
  width: 50%;
  height: 100%;
  margin-top: 5%;
}

.faction-Autobot-wrapper .field-label,
.faction-Decepticon-wrapper .field-label {
  float: left;
  top: 50%;
  transform: translateY(0%);
}

.faction-Autobot-wrapper .field-label-img,
.faction-Decepticon-wrapper .field-label-img {
  display: flex;
  position: relative;
  width: 30px;
  height: 30px;
  max-width: 50px;
  max-height: 50px;
  float: left;
  z-index: 2;

  top: 50%;
  transform: translateY(-25%);
}

.faction-Autobot-radio-wrapper,
.faction-Decepticon-radio-wrapper {
  position: relative;
  float: left;
  z-index: 2;
}

.title-faction {
  writing-mode: tb-rl;
  transform: rotateZ(-180deg);
  -webkit-transform: rotateZ(-180deg);
  float: left;
  clear: left;

}
